<template>
  <div class="slidePlayerComponentContainer" ref="slidePlayerComponentContainer">
    <vue-draggable-resizable
      id="slideContent"
      ref="slideContent"
      :lock-aspect-ratio="true"
      :w="160"
      :h="90"
      @dragging="onDrag"
      @resizing="onResize"
      :parent="false"
    >
      <div class="slidePlayer">
        <img :src="imgSrc" />
        <a :href="linkValue" ref="SlideText" :style="linkText==''?'display:none':'display:block'"
          >{{linkText}}</a
        >
      </div>
    </vue-draggable-resizable>
  </div>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import "vue-draggable-resizable/dist/VueDraggableResizable.css";
import "../scss/slide-player.css";

export default {
  data() {
    return {
      imgSrc:"./SlidesLoading.svg",
      linkText:"",
      linkValue:"#",
    };
  },
  methods: {
    onResize: function (x, y, width, height) {
      this.x = x;
      this.y = y;
      this.width = width;
      this.height = height;
    },
    onDrag: function (x, y) {
      this.x = x;
      this.y = y;
    },
    process(){
      //var i=0;
      //for(i=0;i<this.slides.length;i++){
      //  var parsedTime=this.parseTime(this.slides[i].showTime);
      //  return;
      //}
    },
    parseTime(time){
      console.log(time.split(':'));
    }
  },
  components: {
    "vue-draggable-resizable": VueDraggableResizable,
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style>
</style>